<template>
	<div>
	<a-row type="flex" justify="center" :gutter="24">
        <a-col :span="24" :md="14" class="mb-20">
		    <a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
				<template v-if="!fetchInfo && !_.isEmpty(info)">
					<a-descriptions title="Thông tin" :column="1" class="mt-15">
						<a-descriptions-item label="Mã hóa đơn">
							#{{ info.code }}
						</a-descriptions-item>
						<a-descriptions-item label="Tài nguyên">
							{{ _.get(info, 'data.resources_label') }}
						</a-descriptions-item>
						<a-descriptions-item label="Kỳ hạn">
							{{ `${_.get(info, 'data.interval_label')} (${info.start_time} - ${info.end_time})` }}
						</a-descriptions-item>
						<a-descriptions-item label="Hạn thanh toán">
							{{ info.payment_expired }}
						</a-descriptions-item>
						<a-descriptions-item label="Ngày tạo">
							{{ info.created_at }}
						</a-descriptions-item>
					</a-descriptions>
					<hr class="my-15">
					<a-descriptions title="Số tiền" :column="1">
						<a-descriptions-item label="Tổng tiền">
							{{ currency(info.price) }} VNĐ
						</a-descriptions-item>
						<a-descriptions-item label="Chiết khấu">
							{{ currency(info.discount) }} VNĐ
						</a-descriptions-item>
						<a-descriptions-item label="Thanh toán">
							<h5 class="font-semibold text-primary">{{ currency(info.payment_price) }} VNĐ</h5>
						</a-descriptions-item>
					</a-descriptions>
				</template>
			</a-card>
        </a-col>
    </a-row>
	</div>
</template>

<script>

	import BillService from "@/services/bill"
	import {currency} from '@/helper'
	import {ORDER_TYPE_INSTANCE,ORDER_RESIZE_SERVER,ORDER_TYPE_VOLUME,ORDER_RESIZE_VOLUME,ORDER_TYPE_SNAPSHOT,ORDER_TYPE_IP,ORDER_TYPE_FLOATING_IP} from '@/constant'
	import { notification } from 'ant-design-vue'

	export default ({
		async created(){
			BillService.info(this.$route.params.id)
			.then(res => {
				if (this._.isObject(res) && res.status == 1) {
					this.fetchInfo = false
					this.info = this._.get(res, 'data', {})
				} else {
					this.$router.push({name: '404'})
				}
			})
		},
		data() {
			return {
				fetchInfo: true,
				currency,
				info: {},
			}
		},
		methods: {

		}
	})

</script>
<style lang="scss">
	.input-code{
		width: calc(100% - 200px) !important;
	}
	@media only screen and (max-width: 768px) {
		.input-code{
			width: calc(100% - 100px) !important;
		}
	}
</style>